/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        AOS.init();

        $('.talent_hero').addClass('animate_image');

        $("#js-rotating").Morphext({
          animation: "bounceIn",
          separator: ",",
          speed: 2000
        });

        $(window).scroll(function(){
          var scroll = $(window).scrollTop();
          if((scroll > 0)&&(scroll > 1)){
            $('.icon_large').addClass('scale-img');
          }
          else if(scroll < 400){
            $('.icon_large').removeClass('scale-img');
          }
        })

        $(window).scroll(function(){
          var scroll = $(window).scrollTop();
          if((scroll > 0)&&(scroll > 1)){
            $('.icon_large_agency').addClass('slide_icon');
          }
          else if(scroll < 400){
            $('.icon_large_agency').removeClass('slide_icon');
          }
        })

        $(window).scroll(function(){
          var scroll = $(window).scrollTop();
          if((scroll > 0)&&(scroll > 50)){
            $('.line').addClass('move_down');
          }
          else if(scroll < 400){
            $('.line').removeClass('move_down');
          }
        })


        $('.menu-toggle').on('click', function(){
          $('body').toggleClass('open');
        });


        // $(document).ready(function(){
        //   $("#showPanel").click(function(){
        //     $("#panel").slideToggle();
        //   });
        // });

        // $(document).ready(function(){
        //   $("#showPanel").click(function(){
        //     $("#panel").slideToggle();
        //     $("#panel").toggleClass('show');
        //   });
        // });

        $(document).ready(function() {
          $('#vertical').lightSlider({
            gallery:true,
            item:1,
            vertical:true,
            verticalHeight:295,
            vThumbWidth:50,
            thumbItem:8,
            thumbMargin:4,
            slideMargin:0
          });
        });

        $(window).scroll(function(){
          if ($(window).scrollTop() >= 100) {
            $('.header-banner').addClass('fixed-header');
            $('.icon-log').addClass('bye-logo');
            $('.seed-log').addClass('hi-icon');
          }
          else {
            $('.header-banner').removeClass('fixed-header');
            $('.icon-log').removeClass('bye-logo');
            $('.seed-log').removeClass('hi-icon');

          }
        });

        $(function() {
          $('a[href*=#]').on('click', function(e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top}, 500, 'linear');
          });
        });

        $(function() {
          $( '#collage' ).gridrotator( {
            rows    : 5,
            columns   : 6,
            w1024     : {
              rows  : 5,
              columns : 6
            },
            w768      : {
              rows  : 5,
              columns : 6
            },

            w480      : {
              rows  : 5,
              columns : 6
            },

            w320      : {
              rows  : 5,
              columns : 6
            },

            w240      : {
              rows  : 5,
              columns : 6
            },

            step      : 'random',
            maxStep     : 7,
            preventClick  : true,

            // animation type
            // showHide || fadeInOut || slideLeft ||
            // slideRight || slideTop || slideBottom ||
            // rotateLeft || rotateRight || rotateTop ||
            // rotateBottom || scale || rotate3d ||
            // rotateLeftScale || rotateRightScale ||
            // rotateTopScale || rotateBottomScale || random
            animType    : 'fadeInOut',

            // the item(s) will be replaced every 3 seconds
            // note: for performance issues, the time "can't" be < 300 ms
            interval    : 900,
            animSpeed : 900,
            preventClick  : true

          });

          $( '#logo_grid' ).gridrotator({
            // number of rows
            rows      : 3,
            columns     : 4,
            w1024     : {
              rows  : 3,
              columns : 4
            },

            w768      : {
              rows  : 5,
              columns : 4
            },

            w480      : {
              rows  : 6,
              columns : 2
            },

            w320      : {
              rows  : 6,
              columns : 2
            },

            w240      : {
              rows  : 6,
              columns : 2
            },
            step			: 'random',
            maxStep			: 3,
            preventClick  : true,
            animType    : 'random',
            animSpeed   : 500,
            animEasingOut : 'linear',
            animEasingIn  : 'linear',
            interval    : 2000,
            slideshow   : true,
            onhover   : false,
            nochange    : []
          });

        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var TxtType = function(el, toRotate, period) {
          this.toRotate = toRotate;
          this.el = el;
          this.loopNum = 0;
          this.period = parseInt(period, 10) || 2000;
          this.txt = '';
          this.tick();
          this.isDeleting = false;
        };

        TxtType.prototype.tick = function() {
          var i = this.loopNum % this.toRotate.length;
          var fullTxt = this.toRotate[i];

          if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
          } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
          }

          this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

          var that = this;
          var delta = 200 - Math.random() * 100;

          if (this.isDeleting) { delta /= 2; }

          if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
          } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
          }

          setTimeout(function() {
            that.tick();
          }, delta);
        };
        window.onload = function() {
          var elements = document.getElementsByClassName('typewrite');
          for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtType(elements[i], JSON.parse(toRotate), period);
            }
          }
          // INJECT CSS
          var css = document.createElement("style");
          css.type = "text/css";
          // css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #222}";
          document.body.appendChild(css);
        };
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
